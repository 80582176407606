@use '../../../scss/mixin' as *;
@use '../../../scss/colors' as *;

.results-found-text {
    @include results();
}

.results-found-text-alert {
    @include results();
    color: $red-dark;
}