@use '../../scss/colors' as *;

#hub {

    .align-items-vertical {
        display: grid;
        justify-content: left;
        align-items: stretch;
    }

    .section-wrapper {
        border: 1px solid $white-dark !important;
        border-radius: 5px !important;
        padding: 1rem;
    }

}