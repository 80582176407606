@use './colors' as *;
@use './variables' as *;

// show/hide div panels with fade in transition
@mixin panel-show() {
    visibility: visible;
    opacity: 1;
    height: 100%;
    width: 100%;
    transition: all 1s;
    animation: showMe 1s forwards;
}

@mixin panel-hide() {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    transition: all 1s;
    animation: hideMe 1s forwards;
}

@mixin style-upload-button() {
    input[type=file]::file-selector-button {

        font-family: $font-alt1;
        font-weight: $font-weight-base;
        font-size: $font-size-small;
        line-height: $font-line-height-alt1;
        letter-spacing: $font-letter-spacing-alt1;
        text-transform: uppercase;
        min-width: 64px;
        padding: 5px 15px;
        border-radius: 4px;

        border: 1px solid $bezos-green-alpha-2;
        border-bottom: 1px solid $white-dark;

        color: $bezos-green;
        background-color: inherit !important;
        box-shadow: none;

        text-indent: 0px;
        text-shadow: none;
        text-align: center;

        &:hover {
            text-decoration: none;
            background-color: $bezos-green-alpha-1 !important;
            color: $white !important;
            border: 1px solid $bezos-green;
        }
    }
}

@mixin toggle-visibility() {

    @media only screen and (max-width: 600px) {
        .display-min-width {
            display: inline-block;
        }
        .display-max-width {
            display: none;
        }
      }
      
      @media only screen and (min-width: 601px) {
        .display-min-width {
            display: none;
        }        
        .display-max-width {
            display: inline-block;
        }
      }

}

@mixin tableHeader() {
    // fix to ensure table displays without horizontal scrollbar showing */
    th {
        padding: 0.25rem 0.5rem 1rem 0.5rem;
        vertical-align: bottom;
    }

    td {
        padding: 0.5rem 0.5rem;
    }
}

// used for statuses
@mixin buttonStyle() {
    border-radius: 5px;
    display: inline-block;
    word-break: keep-all;
    width: fit-content;
    font-size: 0.75rem;
    padding: 0.25rem 0.25rem;
    text-align: center;
}

@mixin results() {
    font-size: 50%;
    float: right;
    padding-left: 0.5rem;

    @media only screen and (min-width: 300px) and (max-width: 600px) {
        float: none;
        display: inline-block;
        &::before {
            content: "(";
        }
        &::after {
            content: ")";
        }        
    }
}