@use '../../scss/colors' as *;
@use '../../scss/variables' as *;

#product-view {

    table {

        // fix to ensure table displays without horizontal scrollbar showing
        .MuiTableCell-root {
            padding: 1rem 0.5rem;
            margin: 0;
        }

        .table-cell-clickable {
            cursor: pointer !important;
            word-break: break-all;
        }

        th {
            vertical-align: bottom !important;
            font-weight: $font-weight-heavy;
        }

        td {
            padding: 0.5rem 0.5rem !important;
        }

    }

}
