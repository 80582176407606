@use '../../../scss/mixin' as *;

.upload-file {

    .delete-button {
        cursor: pointer !important;
    }

}

.inline-modal {
    
    @include style-upload-button();

}