// #Create ASN

#goods-in-create {

  // hide/show form panels based on completion
  .panel-hide {
    display: none;
    visibility: hidden;
  }

  .panel-show {
    display: block;
    visibility: visible;
  }

  // if panel minimised grey out
  .panel-active {
    width: 100%;
  }

  .panel-disabled {
    width: 100%;
    
  }

  .date-picker {
    margin-top: 1rem;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px){
    .resize-box-asn {
      min-height: auto !important;
    }
  }

}