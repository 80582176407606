@use '../../scss/colors' as *;

#summary-checker {

    .align-items-vertical {
        display: grid;
        justify-content: center;
        align-items: stretch;
    }

    .card-title {
        vertical-align: top;
        min-height: 0;
    }

    .actions-section {
        .card-title {

            @media only screen and (min-width: 981px) and (max-width: 1310px){
                min-height: 4rem;
            }

            @media only screen and (min-width: 900px) and (max-width: 980px){
                min-height: 6rem;
            }

        }
    }

    .today-section {
        .card-title {

            @media only screen and (min-width: 1200px) and (max-width: 1600px){
                min-height: 4rem;
            }

            @media only screen and (min-width: 900px) and (max-width: 945px){
                min-height: 4rem;
            }

            @media only screen and (min-width: 600px) and (max-width: 850px){
                min-height: 4rem;
            }

        }
    }

    .last-30days-section {
        .card-title {

            @media only screen and (min-width: 1290px) and (max-width: 1600px){
                min-height: 4rem;
            }

            @media only screen and (min-width: 1200px) and (max-width: 1289px){
                min-height: 6rem;
            }

            
            @media only screen and (min-width: 600px) and (max-width: 792px){
                min-height: 4rem;
            }
            

        }
    }

    .card-body {
        min-height: 4rem;
        vertical-align: bottom;
        display: flex;
        justify-content: center;
        align-items: center;

        .card-text {
            font-weight: 900;
            margin-top: 0.5rem;
        }

        .card-image {
            margin-left: 1rem;
        }
    }
}