@use '../../scss/mixin' as *;
@use '../../scss/colors' as *;

#invoice-upload {

    // show/hide invoice upload panel
    .panel-show {
        @include panel-show()
    }
    @keyframes showMe{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    .panel-hide {
        @include panel-hide()
    }
    @keyframes hideMe{
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }

    @include style-upload-button();

    input[type='file'] {
        color: $transparent;
    }

    .upload-file-count {
        display: inline-flex;
        align-items: center;
        font-size: 80%;
        position: relative;
        left: -110px;
    }

}

