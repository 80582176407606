@use '../../../scss/mixin' as *;

#add-products {
  @include style-upload-button();

  .product-image-thumbnail {
    height: 50px;
    width: 60px;
    cursor: pointer;
  }

  // show/hide invoice upload panel
  .panel-show {
      @include panel-show()
  }
  @keyframes showMe{
      0%{
          opacity: 0;
      }
      100%{
          opacity: 1;
      }
  }
  .panel-hide {
      @include panel-hide()
  }
  @keyframes hideMe{
      0%{
          opacity: 1;
      }
      100%{
          opacity: 0;
      }
  }

}
