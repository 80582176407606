@use '../../scss/mixin' as *;

#reports-create {

    // show/hide invoice upload panel
    .panel-show {
        @include panel-show()
    }
    @keyframes showMe{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    .panel-hide {
        @include panel-hide()
    }
    @keyframes hideMe{
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }

    .text-disclaimer {
        font-style: italic;
        font-size: 0.8rem;
        display: flex;
        justify-content: left;
        margin-top: 1rem;
        margin-bottom: 0.2rem;
    }

}