@use '../../../scss/colors' as *;

.courier-description {

    width: 100%;

    a {
        color: $bezos-green;
    
        &:hover {
        color: $bezos-green;
        }
    }

    .MuiList-root {
        list-style-type: disc; 
        padding-left: 2rem;

        .MuiListItem-root {
            display: list-item;
        }
    }

    .warning-message {
        p {
            margin: 0;
        }
    }

}
