@use './variables' as *;
@use './colors' as *;
@use './text' as *;
@use './tables' as *;
@use './mixin' as *;

body {
  min-height: 100vh;
  margin: 0;
  font-family: $font-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
}

em {
  font-style: normal;
  font-weight: $font-weight-heavy;
}

// mainpanel
.invalid-page-alert {
  text-decoration: underline;
  font-weight: $font-weight-heavy;
}

//invoice view 2
#filename-failed {
  color: $red-dark;
}

.bread-crumb {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: inherit;
  }

}

// input fields above table data for Orders/Returns Summary
.form-inputs {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    padding: 0.5rem 0;
  
    @media only screen and (min-width: 300px) and (max-width: 900px){
        justify-content: flex-start;
        
        .grid-search {
          width: 100%;
          padding-top: 1rem;
        }

        .grid-date-range {
          width: 100%;
        }

    }
}

span.label {
  font-weight: $font-weight-heavy;
  min-width: 50px;
  display: inline-block;
  padding-bottom: 5px;
}

.error-alert {
  color: $alert;
  font-family: $font-alt1;
  font-weight: $font-weight-light;
  font-size: $font-size-extra-small;
  line-height: $font-line-height-small;
  letter-spacing: $font-letter-spacing-alt2;
  text-align: left;
  margin: 4px 14px 0px 14px;
}

  // spacing between back button and continue
  .back-button {
    margin-right: 1rem;
  }

  .reload-button {
    float: right;
  }

  // for button group
  .align-content-right {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 1rem;
  }

  .align-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .status-button {
    @include buttonStyle();
  }

.footer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Sign In
.amplify-button--primary {
  background-color: $bezos-blue-light;
  &:hover {
      background-color: $bezos-blue-medium;
  }
  &:active {
      background-color: $bezos-blue-dark
  }
}

// alert fade in
@keyframes glowing {
  0% {
      box-shadow: 0 0 10px $bezos-green-alpha-2;
  }
  50% {
      box-shadow: 0 0 20px $bezos-green-alpha-1;
  }
  100% {
      box-shadow: 0 0 10px $bezos-green-alpha-0;
  }
}
.hidden-alert {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 2s ease-in, visibility 0s linear 2s, height 0s linear 2s;
}
.visible-alert {
  width: 100%;
  visibility: visible;
  opacity: 1;
  height: auto;
  transition: opacity 10s ease-in, visibility 0s linear 0s, height 0s linear 0s;
  animation: glowing 3s ease-in-out 1 forwards;
}
