@use './variables' as *;

table {

  th {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    font-weight: bold !important;
  }

  td {
    padding: 0.5rem, 0 !important;
  }

  // ensures table cells wrap correctly
  .tc-wrap {
    max-width: 110px;
    margin-right: 0.25rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}


.table-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  @media only screen and (max-width: 700px) {
    justify-content: start;
  }

}