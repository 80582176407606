@use '../../../scss/colors' as *;
@use '../../../scss/mixin' as *;

.AppBar {

    // top bar seller dropdown for Admin login
    .MuiInputLabel-root {
        color: $white;
    }

    .MuiOutlinedInput-root {
        background-color: $bezos-green;
        color: $white;

        .MuiSvgIcon-root {
            fill: $white;
        }
    }

    @media only screen and (max-width: 600px) {
        .MuiToolbar-root {
            margin: 0.2rem;
        }
    }

    @include toggle-visibility();

}
