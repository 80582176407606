@use '../../scss/colors' as *;
@use '../../scss/variables' as *;

#settings-view {

    .vertical-align-elements {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

    }

}