@use '../../scss/mixin' as *;
@use '../../scss/colors' as *;

#order-status {

    // status input fix to allow vertical alignment with surrounding inputs
    .status-select {
        .MuiInputLabel-root {
            top: -7px !important;
        }
        .MuiInputLabel-shrink {
            top: 1px !important;
        }
    }

    .filter-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle !important;
        border: 1px solid $white-very-light;
        border-radius: 0.25rem;
        height: 40px;
    }

    @include tableHeader();

}
