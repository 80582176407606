@use '../../../scss/colors' as *;
@use '../../../scss/variables' as *;

.alert-bar { 
    z-index: 3000 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    background-color: $white;
    

    .alert-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
        border-bottom: 1px solid $white-dark;

        p {
            color: $black-medium;
            font-weight: $font-weight-light;
            padding: 0 0 0 1rem !important;
        }

        a {
            text-decoration: none;
            color: $red;
        }

        @media only screen and (min-width: 300px) and (max-width: 900px){
            p {
                font-size: 0.8rem !important;
            }
        }

    }

}


