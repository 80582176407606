@use '../../scss/mixin' as *;

#asn-detail {

    // show/hide panel
    .panel-show {
      @include panel-show()
  }
  @keyframes showMe{
      0%{
          opacity: 0;
      }
      100%{
          opacity: 1;
      }
  }
  .panel-hide {
      @include panel-hide()
  }
  @keyframes hideMe{
      0%{
          opacity: 1;
      }
      100%{
          opacity: 0;
      }
  }

}