.loading-status-box {

    display: grid !important;
    justify-content: center !important;
    align-items: center;
    width: "100%"

    & svg {
        transform: scale(1.5);
    }

    @media only screen and (min-width: 300px) and (max-width: 1150px){
        & p {
            display: none;
            visibility: hidden;
            width: 0;
            height: 0;
        }

        & svg {
            transform: scale(0.5) !important;
         }
    }

}