@use '../../../scss/mixin' as *;

#mui-table {
    .product-image-thumbnail {
        height: 50px;
        width: 60px;
        cursor: pointer;
    }

    @include tableHeader();

}
