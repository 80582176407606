#order-create {

    // hide/show form panels based on completion
    .panel-hide {
        display: none;
        visibility: hidden;
    }
    .panel-show {
        display: block;
        visibility: visible;
    }
    // if panel minimised grey out
    .panel-active {
        width: 100%;
    }
    .panel-disabled {
        width: 100%;

    }
    
}