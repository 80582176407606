@use '../../scss/mixin' as *;
@use '../../scss/colors' as *;
@use '../../scss/variables' as *;

#order-view {
    
    .product-items-list {
        list-style-type: none !important;
        background-color: $bezos-blue;
    }     

    // disabled inputs
    .MuiOutlinedInput-input.Mui-disabled {
        border: none;
        border-radius: none;
    }

    .edit-button-disabled-text {
        font-size: $font-size-small;
        text-decoration: underline;
        color: $white;
        padding-top: 0.5rem;
        padding-right: 1rem;
    }

    .edit-cancel-order-button {
        text-transform: capitalize;
        margin-left: 1rem;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }

    // show/hide panel
    .panel-show {
        @include panel-show()
    }
    @keyframes showMe{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    .panel-hide {
        @include panel-hide()
    }
    @keyframes hideMe{
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }

}
